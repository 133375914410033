@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local('Inter Medium'), local('Inter-Medium'), url('./fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter-Bold';
    font-style: bold;
    font-weight: 400;
    src: local('Inter Bold'), local('Inter-Bold'), url('./fonts/Inter-Bold.ttf') format('truetype');
}

body
{
    font-family: "Inter";
    background-repeat: no-repeat;
    background-color: #F0F5FA;
}

#mainView
{
    margin: 0 auto;
    max-width: 1200px;
}

header
{
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    background: white;
    border-color: white;
    border-bottom-color: black;
    border-style: solid;
    border-width: .12rem;
    padding: .5rem;
}

.container
{
    width: 85%;
    margin: 0 auto;
}

.logo-nav-container
{
    display:flex;
    justify-content:space-between;
    align-items: center;
}

.mainMenu
{
    font-weight: bolder;
    margin-top: 10px;
}

.mainMenu > ul
{
    margin: 0;
    padding: 0;
    list-style: none;
}

.mainMenu > ul li
{
    display: inline-block;
}

.mainMenu > ul li a
{
    color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
}

.mainMenu > ul li label
{
    color: #595858;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
}

.mainMenu a:hover
{
    text-decoration: underline;
}

.submenuItem
{
    border-radius: 5px;
    list-style: none;
    position: absolute;
    background: #14b8f0;
    display: none;
    width: 120px;
    padding: 0 0;
}

.submenuItem li
{
    width: 100%;
}

.submenuItem li a
{
    padding: 5px 0px;
    display: block;
    text-decoration: none;
}

.mainMenu li:hover .submenuItem
{
    display: block;
}

.mainMenu .submenuItem li a:hover
{
    border-radius: 5px;
    background: #015FFF;
    text-decoration: none;
}

#jirafitaBanner
{
    background: url("./images/sovos-logo.svg");
    background-repeat: no-repeat;
    padding-left: 20px;
    height: 50px;
    width: 198px;
    display: inline-block;
}

#selectedMenu
{
    border-color: #14b8f0;
    border-style: dotted;
    border-width: .15rem;
    padding: 2px 25px;
}

#unselectedMenu
{
    border-style: unset
}

#mainContent
{
    padding-top: 3.7rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

h3
{
    font-family: "Inter-Bold";
    font-size: 1.75rem;
    font-weight: 900;
}

button
{
    padding-left: 18px;
    padding-right: 18px;
    height: 32px;
    color: white;
    border-width: 0px;
    border-radius: 5px;
    background-color: #015FFF;
}

button:hover {
    background-color: #14b8f0;
    border-color: #14b8f0;
}

#dashboard
{
    margin:auto;
    width: 100%;
    padding: 10px;
}

.dashboardOption
{
    background: white;
    border-color: white;
    border-bottom-color: black;
    border-style: solid;
    border-width: .12rem;
    top: 4.6rem;
    left: 0;
    right: 0;
    padding: 1rem 0;
}

.dashboardOption ul
{
    margin:auto;
    width: 85%;
}


.dashboardOption li
{
    display: inline-block;
    margin: 0;
}

.dashboardOption li a
{
    padding: 0px 10px;
    display: block;
    text-decoration: none;
    color: #595858;
}

.dashboardOption a:hover
{
    text-decoration: underline;
}

table
{
    margin:auto;
    margin-top: 25px;
    color: #595858;
}

thead
{
    background-color:#74BFFF;
}

thead th
{
    border-radius: 8px 8px 8px 8px;
}

tbody tr:nth-child(odd)
{
    background: #015FFF14;
}

tbody tr:nth-child(odd)
{
    background: #015FFF14;
}

td
{
    padding-left: 20px;
    padding-right: 20px;
}

div[data-automation-context="Organización"] 
{
    display: none;
}